import type { IykUser } from "../hooks/use-iyk-user.ts"

import * as Icon from "@iyk/icons"
import * as UI from "@iyk/ui"
import * as React from "react"
import * as DropdownMenu from "../../lib/ui/dropdown-menu.tsx"

import { toSentenceCase } from "@iyk/string"
import { Link } from "@remix-run/react"
import { AnimatePresence, motion } from "framer-motion"
import { useIykUser } from "../hooks/use-iyk-user.ts"
import { useLogOut } from "../hooks/use-log-out.ts"
import { useLang } from "../lang/use-lang.ts"

const DROPDOWN_ITEMS: ((args: {
  lang: ReturnType<typeof useLang>
  iykUser: IykUser
}) => DropdownItem)[] = [
  ({ lang, iykUser }) => ({
    id: "profile",
    term: lang.terms.MY_PROFILE,
    href: "/profile",
    icon: (props) => <UI.Avatar src={iykUser.avatarUrl ?? undefined} {...props} />,
  }),
  ({ lang }) => ({
    id: "settings",
    term: lang.terms.SETTINGS,
    href: "/settings",
    icon: Icon.Settings,
  }),
  ({ lang }) => ({
    id: "logout",
    term: lang.terms.LOGOUT,
    icon: Icon.Logout,
  }),
]

export const AccountDropdown = () => {
  const { iykUser } = useIykUser()
  const lang = useLang()

  if (!iykUser) return null

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Icon.Profile className="size-6" />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align="end"
        className="min-w-60"
        onCloseAutoFocus={(e) => e.preventDefault()} // prevent focus on trigger when closing
      >
        {DROPDOWN_ITEMS.map((getDropdownItem) => {
          const item = getDropdownItem({ lang, iykUser })
          return item.id === "logout" ? (
            <LogoutAccountDropdownItem key={item.id} item={item} />
          ) : (
            <LinkAccountDropdownItem key={item.id} item={item} />
          )
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

const COMMON_DROPDOWN_ITEM_CLASS_NAME = "gap-3 hover:cursor-pointer"
const COMMON_DROPDOWN_ITEM_ICON_CLASS_NAME = "size-4 text-gray-11"

const LinkAccountDropdownItem = ({ item }: { item: LinkDropdownItem }) => {
  return (
    <DropdownMenu.Item className="gap-3 hover:cursor-pointer" asChild>
      <Link to={item.href}>
        <item.icon className={COMMON_DROPDOWN_ITEM_ICON_CLASS_NAME} />
        {toSentenceCase(item.term)}
      </Link>
    </DropdownMenu.Item>
  )
}

const LogoutAccountDropdownItem = ({ item }: { item: LogoutDropdownItem }) => {
  const { logOut, isLoggingOut } = useLogOut()

  const handleClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    logOut()
  }

  return (
    <DropdownMenu.Item
      className={COMMON_DROPDOWN_ITEM_CLASS_NAME}
      onClick={handleClick}
      disabled={isLoggingOut}
    >
      <item.icon className={COMMON_DROPDOWN_ITEM_ICON_CLASS_NAME} />
      {toSentenceCase(item.term)}
      <AnimatePresence>
        {isLoggingOut && (
          <motion.div
            className="ml-auto origin-center"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            <Icon.LoadingSpinner className="animate-spin size-3" />
          </motion.div>
        )}
      </AnimatePresence>
    </DropdownMenu.Item>
  )
}

type CommonDropdownItem = {
  term: string
  icon: (props: { className: string }) => JSX.Element
}

type LinkDropdownItem = CommonDropdownItem & {
  id: "profile" | "settings"
  href: string
}

type LogoutDropdownItem = CommonDropdownItem & {
  id: "logout"
}

type DropdownItem = LinkDropdownItem | LogoutDropdownItem
