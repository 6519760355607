import * as React from "react"
import * as Shopify from "../shopify/shopify.ts"

import { useClerk } from "@clerk/remix"
import { useRevalidator } from "@remix-run/react"
import { useCart } from "../../components/shop/cart-provider.tsx"
import { CUSTOM_CART_ACTIONS } from "../shopify/cart.ts"

export function useLogOut() {
  const clerk = useClerk()
  const revalidator = useRevalidator()
  const [isLoggingOut, setIsLoggingOut] = React.useState(false)
  const { cart } = useCart()

  const logOut = async () => {
    setIsLoggingOut(true)

    await clerk.signOut()

    // Cart is a deferred value, so we need to resolve before determining if we need to clear the cart
    const cartResolved = await cart
    if (cartResolved) {
      await Shopify.triggerCartAction({ action: CUSTOM_CART_ACTIONS.CLEAR })
    }

    revalidator.revalidate()

    setIsLoggingOut(false)
  }

  return { logOut, isLoggingOut }
}
